<template>
  <div>
    <div class="top">
      <img
        class="background"
        src="@img/head-ads.png"
      />

      <div class="txt-desc">
        <div class="content row">
          <div class="title">
            <span style="color: black">{{txtDesc.title[0]}}</span>
            <span style="color: rgb(214, 27, 128)">{{txtDesc.title[1]}}</span>
          </div>
          <div class="txt">
            {{txtDesc.txt[0]}}<br />
            {{txtDesc.txt[1]}}<br />
            {{txtDesc.txt[2]}}<br />
            {{txtDesc.txt[3]}}<br />
          </div>
        </div>
      </div>

      <div class="pic-desc">
        <div class="row">
          <div class="title">
            {{picDesc.title}}

          </div>
          <div class="split">
            <img src="@img/ad-scene-split.png" />
          </div>
          <div class="pics">
            <!-- <img
                v-for="item, index in picDesc.pics"
                :src="item"
                :key="index"
              /> -->
            <img src="../assets/img/1.png" />
            <img src="../assets/img/2.png" />
            <img src="../assets/img/3.png" />
            <img src="../assets/img/4.png" />
          </div>
          <div class="pics">
            <!-- <img
                v-for="item, index in picDesc.pics"
                :src="item"
                :key="index"
              /> -->
            <img src="../assets/img/5.png" />
            <img src="../assets/img/6.png" />
            <img src="../assets/img/7.png" />
            <img src="../assets/img/8.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="integrate">
      <div class="row">
        <div class="title">
          <span>{{integrate.title[0]}}</span> <span style="color: rgb(214, 27, 128)">{{integrate.title[1]}}</span>
        </div>
        <div class="split">
          <img src="@img/ad-scene-split.png" />
        </div>
        <div class="progress">
          <div class="item">
            <img
              src="@img/integration.png"
              alt=""
            />
            <div class="sub-title">{{integrate.progress[0].title}}</div>
            <div class="sub-txt">{{integrate.progress[0].txt}}</div>
          </div>
          <div class="arrow">
            <img
              src="@img/arrow.png"
              alt=""
            >
          </div>
          <div class="item">
            <img
              src="@img/integration.png"
              alt=""
            />
            <div class="sub-title">{{integrate.progress[1].title}}</div>
            <div class="sub-txt">{{integrate.progress[1].txt}}</div>
          </div>
          <div class="arrow">
            <img
              src="@img/arrow.png"
              alt=""
            >
          </div>
          <div class="item">
            <img
              src="@img/integration.png"
              alt=""
            />
            <div class="sub-title">{{integrate.progress[2].title}}</div>
            <div class="sub-txt">{{integrate.progress[2].txt}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import App from '../App.vue'
export default {
  name: 'Advertise',
  data () {
    return {
      txtDesc: {
        title: ["What Is", " Interactive Ads"],
        txt: [
          "It is a brand-new form of advertising that adds new advertising entrance without occupying original ads space.",
          "After the user clicks to enter this entrance, a variety of interesting interactions will be displayed, such as scratch ticket, lucky draw, puzzles, etc.",
          "Users voluntarily participate in these activities and have a chance to win coupons, membership cards and other welfare rewards.",
          "By clicking on the corresponding rewards, users will be redirected to the brand advertising landing page."],
      },

      picDesc: {
        title: "Diversified Traffic Monetization Scenarios",
        pics: ["../assets/img/1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png"],
      },

      integrate: {
        title: ["How to", " Integrate"],
        progress: [
          { img: "/img/register.png", title: "Register", txt: "Register an account, create an app" },
          { img: "../assets/img/integration.png", title: "Integration", txt: "SDK  Tracking Link  JS Tag" },
          { img: "../assets/img/monetize.png", title: "Monetize", txt: "" },

        ]
      }
    }
  },
  components: {
    App

  }
}
</script>

<style>
.row {
  width: 80%;
  margin: 40px auto;
}

.txt-desc {
  width: 100%;
}
.txt-desc .title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.txt-desc .txt {
  font-size: 26px;
  line-height: 48px;
}

.pic-desc .title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.split {
  margin: 20px auto;
  width: 150px;
}

.pic-desc .pics {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.integrate .title {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

.integrate .progress {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.progress .item {
  text-align: center;
}

.arrow {
  width: 16px;
  height: 31px;
}

.sub-title {
  font-size: 28px;
  color: rgb(88, 94, 103);
}

.sub-txt {
  font-size: 20px;
  color: rgb(126, 126, 126);
}
</style>